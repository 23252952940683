import Vue from 'vue'
import Router from 'vue-router'

//General
const Login = () => import('@/views/general/Login')
const Signup = () => import('@/views/general/Signup')

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views for User
const Discipleship = () => import('@/views/user/Discipleship')
const Fellowship = () => import('@/views/user/Fellowship')
const Resources = () => import('@/views/user/Resources')

// Views for Leader
const MemberSignup = () => import('@/views/leader/MemberSignup')
const MemberUpdate = () => import('@/views/leader/MemberUpdate')
const DiscipleshipJournal = () => import('@/views/leader/DiscipleshipJournal')
const FellowshipPlan = () => import('@/views/leader/FellowshipPlan')

// Views for Admin
const DownloadReport = () => import('@/views/admin/DownloadReport')
const DiscipleshipPlan = () => import('@/views/admin/DiscipleshipPlan')
const ManageLink = () => import('@/views/admin/ManageLink')
const ManagePeriod = () => import('@/views/admin/ManagePeriod')
const ManageCity = () => import('@/views/admin/ManageCity')

Vue.use(Router)

export default new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes () {
  return [
    {
      path: '/login',
      name: 'Login',
      component: Login,
    },
    {
      path: '/signup',
      name: 'Signup',
      component: Signup,
    },
    {
      path: '/discipleship',
      redirect: '/discipleship',
      name: 'Discipleship',
      component: TheContainer,
      children: [
        {
          path: 'bahan-pa',
          name: 'Discipleship Access',
          component: Discipleship
        },
      ]
    },
    {
      path: '/fellowship',
      redirect: '/fellowship',
      name: 'Fellowship',
      component: TheContainer,
      children: [
        {
          path: 'sion-raya',
          name: 'Fellowship Access',
          component: Fellowship
        },
      ]
    },
    {
      path: '/resources',
      redirect: '/resources',
      name: 'Resources',
      component: TheContainer,
      children: [
        {
          path: 'link',
          name: 'Resources Access',
          component: Resources
        },
      ]
    },
    {
      path: '/leader',
      redirect: '/leader',
      component: TheContainer,
      children: [
        {
          path: 'approve-sioner',
          name: 'Approve Sioner',
          component: MemberSignup
        },
        {
          path: 'update-sioner',
          name: 'Update Sioner',
          component: MemberUpdate
        },
        {
          path: 'jurnal-pa',
          name: 'Jurnal Pemuridan',
          component: DiscipleshipJournal
        },
        {
          path: 'jadwal-sion-raya',
          name: 'Jadwal Sion Raya',
          component: FellowshipPlan
        },
      ]
    },
    {
      path: '/admin',
      redirect: '/admin',
      component: TheContainer,
      children: [
        {
          path: 'download',
          name: 'Download',
          component: DownloadReport
        },
        {
          path: 'atur-bahan-pa',
          name: 'Atur Bahan PA',
          component: DiscipleshipPlan
        },
        {
          path: 'manage-link',
          name: 'Manage Link',
          component: ManageLink
        },
        {
          path: 'manage-period',
          name: 'Manage Period',
          component: ManagePeriod
        },
        {
          path: 'manage-city',
          name: 'Manage City',
          component: ManageCity
        }
      ]
    },
  ]
}

