import 'core-js/stable'
import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'

import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store'
import GSignInButton from 'vue-google-signin-button'
import VueSimpleAlert from "vue-simple-alert"
import vueFilterPrettyBytes from 'vue-filter-pretty-bytes'
import VueCountryCode from "vue-country-code-select"
import CKEditor from '@ckeditor/ckeditor5-vue2'

Vue.use(CKEditor)
Vue.use(vueFilterPrettyBytes)
Vue.use(VueSimpleAlert);
Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.use(GSignInButton)
Vue.use(VueCountryCode)

Vue.prototype.$log = console.log.bind(console)

new Vue({
  el: '#app',
  router,
  store,
  icons,
  template: '<App/>',
  components: {
    App
  }
})
