<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App',
  data () {
    return {
    }
  },

  mounted () {
    this.$root.$on('googleSignInOnSuccess', (idToken, user) => {
      localStorage.setItem('idToken', idToken)
      localStorage.setItem('user', user)
      this.$router.replace('/discipleship/bahan-pa', () => {})
    })
  },

  async created () {
    this.validateIdToken()
  },

  methods: {
    validateIdToken () {
      var idToken = localStorage.getItem('idToken')

      if(idToken === 'undefined' || idToken === undefined || idToken === null){
        localStorage.clear()
        this.$router.replace('/login', () => {})
      }
      else{
        console.log("=>app.vue validateIdToken() true")
        this.$router.replace('/discipleship/bahan-pa', () => {})
      }
    },
  }
}
</script>

<style lang="scss">
  // Import Main styles for this application
  @import 'assets/scss/style';
</style>
